import Snowfall from "react-snowfall";
import { useTheme } from "@/providers/ThemeProvider";
import { BorderBeam } from "@/components/magicui/border-beam";
import { useEffect, useMemo, useState } from "react";

const bgImages = [
  "/assets/media/0c05101a-991f-42c4-948c-137ca8adbb76.webp",
  "/assets/media/54c2b767-5fea-4cf0-93c2-48e0d35ae399.webp",
  "/assets/media/a507a8d9-1701-4961-8082-0397435097e0.webp",
  "/assets/media/a2bc3071-d9d4-496d-838f-7bfae81e43b9.webp",
  "/assets/media/565c5001-2936-4354-b27e-7028167d4e9c.webp",
  "/assets/media/dde3f1e6-66b6-4ccc-b155-f91a6aacaff1.webp",
];

export default function GuestLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { theme } = useTheme();
  const logoImage = "/assets/images/logos/logo-lite-new.png";
  const themedLogoImage =
    theme === "dark"
      ? "/assets/images/logos/logo-lite-new.png"
      : "/assets/images/logos/logo-dark-new.png";

  const randomizedBgImages = useMemo(() => {
    return [...bgImages].sort(() => Math.random() - 0.5);
  }, []);

  const [currentBgImageIndex, setCurrentBgImageIndex] = useState(() =>
    Math.floor(Math.random() * randomizedBgImages.length),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgImageIndex(
        (prevIndex) => (prevIndex + 1) % randomizedBgImages.length,
      );
    }, 6000); // Interval between image changes

    return () => clearInterval(interval);
  }, [randomizedBgImages]);

  return (
    <div className="flex min-h-full flex-1">
      <div className="relative hidden w-0 flex-1 lg:block">
        <div className="absolute z-20">
          <div className="relative p-8">
            {isChristmasPeriod() ? (
              <img
                src="/assets/images/logos/logo-lite-new-christmas.png"
                alt="QVirtual's Christmas logo"
                className="h-12"
              />
            ) : (
              <img src={logoImage} alt="QVirtual's logo" className="h-10" />
            )}
          </div>
        </div>
        <div className="absolute inset-0 bg-black/20 z-10" />
        <div className="absolute inset-0 overflow-hidden bg-black">
          {randomizedBgImages.map((bgImage, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-all duration-1500 ease-in-out ${currentBgImageIndex === index ? "opacity-100 scale-100" : "opacity-0 scale-105"}`}
              style={{ zIndex: currentBgImageIndex === index ? 1 : 0 }}
            >
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src={bgImage}
                alt=""
              />
            </div>
          ))}
        </div>
        {isChristmasPeriod() && (
          <Snowfall
            style={{ zIndex: "10" }}
            // The color of the snowflake, can be any valid CSS color.
            color="#FFF"
            // Applied to the canvas element.
            // Controls the number of snowflakes that are created (defaults to 150).
            snowflakeCount={200}
            speed={[0.5, 1]}
          />
        )}
      </div>

      <div className="flex flex-1 absolute z-10 inset-0 lg:inset-auto lg:right-0 h-full">
        <div className="w-[500px] mx-auto lg:m-8 bg-background/20 backdrop-blur rounded-lg shadow-none lg:shadow-md overflow-hidden">
          {randomizedBgImages.map((bgImage, index) => (
            <div
              key={index}
              className={`absolute hidden lg:block inset-0 transition-all duration-1500 ease-in-out ${currentBgImageIndex === index ? "opacity-40 scale-100" : "opacity-0 scale-105"}`}
              style={{ zIndex: currentBgImageIndex === index ? 1 : 0 }}
            >
              <img
                className="absolute inset-0 h-full w-full object-cover scale-150 rotate-180 blur-[60px]"
                src={bgImage}
                alt=""
              />
            </div>
          ))}
          <div className="absolute top-4 left-4 lg:hidden">
            <div className="relative">
              <img
                src={themedLogoImage}
                alt="QVirtual's logo"
                className="h-10"
              />
            </div>
          </div>
          <BorderBeam className="hidden lg:block" />
          <div className="absolute z-20 inset-0">{children}</div>
        </div>
      </div>
    </div>
  );
}

const isChristmasPeriod = () => {
  const today = new Date();
  const christmasStart = new Date(today.getFullYear(), 10, 14);
  const christmasEnd = new Date(today.getFullYear(), 11, 31);
  return today >= christmasStart && today <= christmasEnd;
};
