import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { NavItem } from "@/hooks/usePageData";
import { useEffect } from "react";

type areaPropType = "body" | "bottom";

export const useQvNavigation = (area: areaPropType = "body") => {
  const section = window.location.pathname.includes("qvac") ? "qvac" : "crew";

  const query = useQuery({
    queryKey: ["navigation", section, area],
    queryFn: async () => {
      const response = await axios.post<
        { section: string; items: NavItem[] }[]
      >(route("api.navigation", { section, area }));
      return response.data;
    },
    placeholderData: (prev) => prev,
  });

  const { refetch } = query;

  useEffect(() => {
    window.Echo.channel("qvac-navigation").listen(".changed", refetch);
  }, [refetch]);

  return query;
};
