import { useState, useEffect, useRef, RefObject } from "react";

type UseDivHeightResult = [number, RefObject<HTMLDivElement>];

export const useDivHeight = (): UseDivHeightResult => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHeight = () => {
      if (ref.current) {
        setHeight(ref.current.offsetHeight);
      }
    };

    // Update height on mount and resize
    updateHeight();
    window.addEventListener("resize", updateHeight);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return [height, ref];
};
