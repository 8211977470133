import { usePage } from "@inertiajs/react";

import { Loyalty } from "@/types/Auth";
import { TBreadcrumb } from "@/types/breadcrumb-types";

export type NavItem = {
  id?: string;
  parentId?: string | null;
  label: string;
  type:
    | "workspace"
    | "link"
    | "header"
    | "logout"
    | "dropdown"
    | "livechat"
    | "divider";
  class?: string | null;
  icon?: string | null;
  link?: string | null;
  new_window?: boolean;
  visible?: boolean;
  submenu?: NavItem[] | null;
  badge?: number | null;
};

export type Auth = {
  pilot_id: number;
  first_name: string;
  last_name: string;
  email: string;
  tawkHash: string;
  total_hours: string;
  total_flights: string;
  total_pay: string;
  ff_status: number;
  ff_level: keyof typeof Loyalty;
  country_code: string;
  dob: string;
  staff: boolean;
};

export type PageData = {
  breadcrumbs: TBreadcrumb[];
  environment: string;
  version: string;
  errors: Record<string, unknown>;
  company: {
    companyName: string;
  };
  auth: Auth;
  platform: {
    version: string;
    primaryAssetLevel: string;
  };
};

export const usePageData = () => {
  const pageData = usePage().props as PageData;
  console.info({ pageData });
  return pageData;
};
