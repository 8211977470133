import { Fragment } from "react";

import { Icon } from "@/components/Icon";

import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "@/components/dropdown";
import { Navbar, NavbarItem, NavbarSection } from "@/components/navbar";
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from "@/components/sidebar";
import { useTheme } from "@/providers/ThemeProvider";
import { SidebarLayout } from "@/components/sidebar-layout";
import { NavItem, usePageData } from "@/hooks/usePageData";
import { Badge } from "@/components/badge";
import { useTawkMessenger } from "@/providers/TawkMessengerProvider";
import { useQvNavigation } from "@/hooks/useQvNavigation";

export default function Layout({ children }: { children: React.ReactNode }) {
  const { theme, toggleTheme } = useTheme();

  return (
    <SidebarLayout
      navbar={
        <Navbar>
          <NavbarSection className="shrink-0">
            <NavbarItem onClick={() => toggleTheme()} aria-label="Theme toggle">
              {theme === "light" ? (
                <Icon icon={"fa-moon"} iconStyle="SOLID" />
              ) : (
                <Icon icon={"fa-sun-bright"} />
              )}
            </NavbarItem>
            <UserDropdown>
              <DropdownButton as={NavbarItem}>
                <Icon
                  icon={"fa-user-pilot"}
                  iconStyle="SOLID"
                  className="fa-lg"
                />
              </DropdownButton>
            </UserDropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={<Aside />}
    >
      {children}
    </SidebarLayout>
  );
}

const UserDropdown = ({ children }: { children: React.ReactNode }) => {
  const { auth, version } = usePageData();
  return (
    <Dropdown>
      {children}
      <DropdownMenu className="min-w-64" anchor="bottom end">
        {auth.staff && (
          <>
            <DropdownItem href={route("qvac.dashboard")} className="gap-2">
              <Icon icon={"fa-hammer"} iconStyle="SOLID" />
              <DropdownLabel>Administration</DropdownLabel>
            </DropdownItem>
            <DropdownDivider />
          </>
        )}
        <DropdownItem
          href={route("crew.show", auth.pilot_id)}
          className="gap-2"
        >
          <Icon icon={"fa-user-pilot"} iconStyle="SOLID" />
          <DropdownLabel>My profile</DropdownLabel>
        </DropdownItem>
        <DropdownItem href={route("crew.settings.index")} className="gap-2">
          <Icon icon={"fa-cog"} iconStyle="SOLID" />
          <DropdownLabel>Account settings</DropdownLabel>
        </DropdownItem>
        <DropdownItem
          as={"button"}
          href={route("inertia.logout")}
          method={"post"}
          className="gap-2"
        >
          <Icon icon={"fa-right-from-bracket"} iconStyle="SOLID" />
          <DropdownLabel className="">Sign out</DropdownLabel>
          <span className="text-xs text-gray-400">{version}</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const Aside = () => {
  return (
    <Sidebar>
      <AsideHeader />
      <AsideBody />
      <AsideFooter />
    </Sidebar>
  );
};

const AsideHeader = () => {
  const { theme } = useTheme();
  const logoImage =
    theme === "light"
      ? "/assets/images/logos/logo-dark-new.png"
      : "/assets/images/logos/logo-lite-new.png";

  const adminUrl = window.location.href.includes("qvac");
  const adminDashboard = route("qvac.dashboard");
  const crewDashboard = route("crew.dashboard");
  const dashboardUrl = adminUrl ? adminDashboard : crewDashboard;
  const dashboardActive = window.location.href.split("?")[0] === dashboardUrl;
  return (
    <SidebarHeader>
      <div className="flex-1">
        <img src={logoImage} className="h-8 w-auto" alt={"QVirtual's logo"} />
      </div>
      <SidebarSection className="mt-4">
        {adminUrl && (
          <SidebarItem href={crewDashboard}>
            <Icon icon={"fa-chevron-left"} iconStyle="SOLID" />
            <SidebarLabel>Return</SidebarLabel>
          </SidebarItem>
        )}
        <SidebarItem current={dashboardActive} href={dashboardUrl}>
          <Icon icon={"fa-house"} iconStyle="SOLID" />
          <SidebarLabel>Dashboard</SidebarLabel>
        </SidebarItem>
      </SidebarSection>
    </SidebarHeader>
  );
};

const AsideLink = ({ item }: { item: NavItem }) => {
  const isActive = window.location.href.split("?")[0] === item.link;
  const key = item.id;

  return (
    <Fragment key={key}>
      <SidebarItem current={isActive} href={item?.link || ""}>
        {item.icon && <Icon icon={item.icon} iconStyle="SOLID" />}
        <SidebarLabel className="flex-1">{item.label}</SidebarLabel>
        {item.badge !== null && <Badge>{item.badge}</Badge>}
      </SidebarItem>
    </Fragment>
  );
};

const NavSection = (props: {
  section: { section?: string; items: NavItem[] };
}) => {
  return (
    <SidebarSection>
      {props.section?.section && (
        <SidebarHeading>{props.section.section}</SidebarHeading>
      )}
      {props.section.items.map((item) => (
        <AsideLink key={item.label} item={item} />
      ))}
    </SidebarSection>
  );
};

const AsideBody = () => {
  const { data } = useQvNavigation();
  const { theme, toggleTheme } = useTheme();
  const { toggleChat } = useTawkMessenger();
  return (
    <SidebarBody>
      <div className="flex flex-col gap-y-4">
        {data?.map((section, idx) => {
          return <NavSection key={section.section + idx} section={section} />;
        })}
      </div>
      <SidebarSpacer />
      <SidebarSection>
        <SidebarItem
          onClick={() => {
            const link = "https://discord.qvirtual.com.au";
            window.open(link, "_blank");
          }}
        >
          <Icon icon={"fa-brands fa-discord"} iconStyle="SOLID" />
          <SidebarLabel>Discord</SidebarLabel>
        </SidebarItem>
        <SidebarItem
          onClick={() => {
            const link = "https://community.qvirtual.com.au";
            window.open(link, "_blank");
          }}
        >
          <Icon icon={"fa-message"} iconStyle="SOLID" />
          <SidebarLabel>QCommunity</SidebarLabel>
        </SidebarItem>
        <SidebarItem onClick={toggleChat}>
          <Icon icon={"fa-circle-question"} iconStyle="SOLID" />
          <SidebarLabel>Support</SidebarLabel>
        </SidebarItem>
        <SidebarItem onClick={() => toggleTheme()}>
          {theme === "light" ? (
            <Icon icon={"fa-moon"} iconStyle="SOLID" />
          ) : (
            <Icon icon={"fa-sun-bright"} />
          )}
          <SidebarLabel>Toggle theme</SidebarLabel>
        </SidebarItem>
      </SidebarSection>
    </SidebarBody>
  );
};

const AsideFooter = () => {
  const { auth } = usePageData();
  return (
    <SidebarFooter className="max-lg:hidden">
      <UserDropdown>
        <DropdownButton as={SidebarItem}>
          <span className="flex min-w-0 items-center gap-3 flex-1">
            <Icon icon={"fa-user-pilot"} iconStyle="SOLID" className="fa-xl" />
            <span className="min-w-0">
              <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                {auth.first_name} {auth.last_name}
              </span>
              <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                QFA{auth.pilot_id}
              </span>
            </span>
          </span>
          <Icon icon={"fa-chevron-up"} iconStyle="SOLID" />
        </DropdownButton>
      </UserDropdown>
    </SidebarFooter>
  );
};
